import { Col, Container, Row } from "react-bootstrap";
// import apple from "./apple-download.png";
import android from "./android-download.png";
import Nav from "react-bootstrap/Nav";
import "./AppDownload.css";

const AppDownload = () => {
  return (
    <Container fluid className="appdownload">
      <Container fluid="sm">
        <Row>
          <Col lg="3">
            <h1 className="Contacthd p-0 mb-4 text-white">
              <span className="underline">D</span>ownload now
            </h1>
          </Col>
          <Col
            lg="7"
            className=" d-flex flex-column flex-md-row m-auto justify-content-around align-items-center"
            id="about">
            <Nav.Link
              href="https://play.google.com/store/apps/details?id=com.app.fizido"
              target="_blank"
              className="ms-0 ms-md-3 text-white">
              <img src={android} alt="" />
            </Nav.Link>

            {/* <Nav.Link
              href="https://play.google.com/store/apps/details?id=com.app.fizido"
              target="_blank"
              className="mt-3 mt-md-0 p-0 text-white">
              <img src={apple} alt="" />
            </Nav.Link> */}
          </Col>
        </Row>
        <div className="d-inline"></div>
      </Container>
    </Container>
  );
};

export default AppDownload;
