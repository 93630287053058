import { Nav, Container } from "react-bootstrap";
import { MdArrowBackIosNew } from "react-icons/md";

import "./privacy.css";
const Privacy = () => {
  return (
    <div className="pri-container">
      <Container fluid className="privacy">
        <header>
          <span>
            <Nav.Link href="/" className="pt-0 d-flex ftext priLink">
              <MdArrowBackIosNew className="pricon me-2" />
            </Nav.Link>
          </span>
          <h1>PRIVACY POLICY</h1>
        </header>
        <Container fluid="lg" className="mt-3">
          <p>
            Fizido Global Ventures Limited (Fizido) as the data collector,
            Fizido (“we” “us” “our”) are committed to protecting and respecting
            your privacy. This policy sets out the basis on which any personal
            data we collect from you, or that you provide to us, will be
            processed by us. Please read the following carefully to understand
            our views and practices regarding your personal data and how we will
            treat it. By visiting “https://www.fizido.com” or using the Fizido
            mobile application hosted on the Google Play Store, Apple Store and
            any other distribution network (collectively “our site”) you are
            accepting and consenting to the practices described in this policy.
            The collection and processing of your personal data is in accordance
            with the National Information Technology Development Agency Act 2007
            (the “Act”), Nigeria Data Protection Regulation 2019 (the
            “Regulations”) and the provisions and prescriptions of Section 5;
            Part 1 and Part 2 of National Information Systems and Network
            Security Standards and Guidelines
          </p>
          <h4>Information we may collect from you</h4>
          <>We may collect and process the following data about you:</>
          <ol>
            <li>
              Information you give us: <br />
              <>
                You may give us information about you by filling in forms on our
                site https://www.fizido.com and by allowing us to access your
                information on social media sites (including but not limited to
                Facebook, Twitter and LinkedIn) or by corresponding with us by
                phone, e-mail or otherwise. This includes information you
                provide when you register to use our site, apply for a loan,
                search for a feature (for example but not limited to, loan
                amounts, currency, loan duration), your activity levels on
                boards or other social media functions on the applicable social
                media sites, the applications you use on social media sites, and
                when you report a problem with our site. The information you
                give us may include your name, address, e-mail address and phone
                number, login information for social networking sites, financial
                and credit card information, personal description, current and
                former places of employment, education, names of colleagues,
                contacts and friends, photographs, and lists of family members.
              </>
            </li>
            <li>
              Information we collect about you: <br />
              <>
                With regard to each of your visits to our site we may
                automatically collect the following information:
              </>
              <ol>
                <li>
                  Technical information, including the Internet protocol (IP)
                  address used to connect your computer to the Internet, your
                  login information, browser type and version, time zone
                  setting, browser plug-in types and versions, operating system
                  and platform;
                </li>
                <li>
                  Information about your visit, including the full Uniform
                  Resource Locators (URL) clickstream to, through and from our
                  site (including date and time); products you viewed or
                  searched for; page response times, download errors, length of
                  visits to certain pages, page interaction information (such as
                  scrolling, clicks, and mouse-overs), and methods used to
                  browse away from the page and any phone number used to call
                  our customer service number.;
                </li>
                <li>Anonymized repayment data.</li>
              </ol>
            </li>
            <li>
              Information we receive from others: <br />
              We may receive information about you if you use any of the other
              websites we operate, the other services we provide and/or the
              social media sites which you login to via our site. We are also
              working closely with third parties (including, for example,
              business partners, sub-contractors in technical, payment and
              delivery services, social media sites, advertising networks,
              analytics providers, search information providers, credit bureaus,
              and financial services and credit providers) and may receive
              information about you from them.
            </li>
          </ol>
          <h4>Cookies</h4>
          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site. By
            visiting our website, you consent to the placement of cookies and
            beacons in your browser and HTML-based emails in accordance with
            this Privacy Policy.
          </p>
          <h5>Uses made of the information</h5>
          <>We use information held about you in the following ways:</>
          <ol>
            <li>
              Information you give us: <br />
              We will use your information in the following ways and in each
              case, we note the lawful basis under the Nigerian Data Protection
              Regulations which we rely on to use your information:
              <ol>
                <li>
                  To determine whether to provide a loan to you, the amount of
                  such loan and the terms and conditions applicable to such loan
                  or for the provision of any of our product and services to
                  you.
                </li>
                <span className="fw-bold">
                  Lawful basis- Contract performance, consent, legal obligation
                </span>
                <li>
                  To investigate or resolve any complaint and issues you may
                  have.
                </li>
                <span className="fw-bold">
                  Lawful basis- Contract performance, consent
                </span>
                <li>
                  To exercise our rights under contracts we have entered into
                  with you like recovering any payments due to us and where
                  necessary to enforce recovery through debt collection agencies
                  or taking other legal action, including instituting an action
                  in the courts of law.
                </li>
                <span className="fw-bold">
                  Lawful basis- Contract performance
                </span>
                <li>
                  To fulfill our legal, compliance, regulatory and risk
                  management obligations.
                </li>
                <span className="fw-bold">Lawful basis- Legal obligation</span>
                <li>
                  To establish a credit rating based on your information and
                  provide that rating to third parties who may be interested in
                  offering you financial products and services and this
                  information is provided only to third parties selected by you
                  or where you have agreed that we may share your score and
                  information with;
                </li>
                <span className="fw-bold">Lawful basis: Consent</span>
                <li>
                  To provide you with information about other goods and services
                  we offer that are similar to those that you have already
                  purchased or enquired about; anonymized repayment data.
                </li>
                <span className="fw-bold">Lawful basis: Consent</span>
                <li>
                  To notify you about changes to our standard terms of service
                  or to our business;
                </li>
                <span className="fw-bold">
                  Lawful basis- Contract performance
                </span>
                <li>
                  To ensure that content from our site is presented in the most
                  effective manner for you and for your device.
                </li>
              </ol>
            </li>
            <li>
              Information we collect about you: <br />
              We will use this information:
              <ol>
                <li>
                  We collect this information to establish a credit rating for
                  you.
                </li>
                <li>
                  We may combine this information with information you give to
                  us. We may use this information and the combined information
                  for the purposes set out above (depending on the types of
                  information we receive).
                </li>
                <li>
                  We may carry out further processing of your data for archiving
                  purposes in the public interest, scientific or historical
                  research purposes or statistical purposes.
                </li>
              </ol>
            </li>
          </ol>
          <h4>Disclosure of your information</h4>
          <p>
            We may share your personal information with any member of our group,
            which means our subsidiaries, our ultimate holding company and its
            subsidiaries. We may share your information with selected third
            parties including:
          </p>
          <ol>
            <li>
              Business partners, suppliers and subcontractors for the
              performance of any contract we enter into with them or you.
            </li>
            <li>
              Advertisers and advertising networks that require the data to
              select and serve relevant adverts to you and others. We do not
              disclose information about identifiable individuals to our
              advertisers, but we may provide them with aggregate information
              about our users (for example, we may inform them that 500 men aged
              under 30 have clicked on their advertisement on any given day). We
              may also use such aggregate information to help advertisers reach
              the kind of audience they want to target (for example, women in
              Lagos State). We may make use of the personal data we have
              collected from you to enable us to comply with our advertisers’
              wishes by displaying their advertisement to that target audience.
            </li>
            <li>
              Analytics and search engine providers that assist us in the
              improvement and optimization of our site. We may disclose your
              personal information to third parties: a) In the event that we
              sell or buy any business or assets, in which case we may disclose
              your personal data to the prospective seller or buyer of such
              business or assets. b) If Fizido or substantially all of its
              assets are acquired by a third party, in which case personal data
              held by it about its customers will be one of the transferred
              assets.
            </li>
            <li>
              if we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our terms of use and other agreements or to protect the
              rights, property, or safety of Fizido, our customers, or others.
              This includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction.
            </li>
          </ol>
          <h4>International data transfers</h4>
          <p>
            Information that we collect may be stored, processed in, and
            transferred between any of the countries in which we operate, in
            order to enable us to use the information in accordance with this
            policy. Any such information transferred to other countries shall be
            in compliance with the Act and the Regulations.
          </p>

          <h4>Customer consent</h4>
          <>
            By ticking the “I agree to the Terms and Conditions”, which you
            hereby adopt as your electronic signature, you consent and agree
            that:
          </>
          <ol>
            <li>
              We can provide materials and other information about your legal
              rights and duties to you electronically.
            </li>
            <li>
              We are authorized to share, receive and use data/information
              collected from your transaction with other affiliated third
              parties including but not limited to switching companies, mobile
              network operators, electricity companies, aggregators, credit
              bureaus, other financial institutions, e-commerce platforms etc.
            </li>
          </ol>
          <h4>Where we store your personal data</h4>
          <p>
            The data that we collect from you is stored on our secure servers
            using JWT. Where we have given you (or where you have chosen) a
            password which enables you to access certain parts of our site, you
            are responsible for keeping this password confidential. We ask you
            not to share a password with anyone. Unfortunately, the transmission
            of information via the internet is not completely secure. Although
            we will do our best to protect your personal data, we cannot
            guarantee the security of your data transmitted to our site; any
            transmission is at your own risk. Once we have received your
            information, we will use strict procedures and security features to
            try to prevent unauthorized access.
          </p>
          <h4>Data retention</h4>
          <p>
            Unless a longer retention period is required or permitted by law, we
            will only hold your data on our systems for the period necessary to
            fulfill the purposes outlined in this privacy policy.
          </p>
          <p>
            Even if we delete your data, it may persist on backup or archival
            media for legal, tax or regulatory purposes.
          </p>
          <h4>Your rights</h4>
          <p>
            You have the right to ask us not to process your personal data for
            marketing purposes and to withdraw your consent at any time. Please
            note that the withdrawal of your consent will not affect the lawful
            processing of data which we have obtained based on your previous
            consent. We will usually inform you (before collecting your data) if
            we intend to use your data for the aforementioned purposes or if we
            intend to disclose your information to any third party for such
            purposes. You can exercise your right to prevent such processing by
            checking certain boxes on the forms we use to collect your data. You
            can also exercise the right at any time by contacting us using
            in-app support feature on the mobile application or using the
            contact on the website at https://www.fizido.com/contact/. Our site
            may, from time to time, contain links to and from the websites of
            our partner networks, advertisers and affiliates. If you follow a
            link to any of these websites, please note that these websites have
            their own privacy policies and that we do not accept any
            responsibility or liability for these policies. Please check these
            policies before you submit any personal data to these websites.
          </p>
          <h4>Remedies</h4>
          <p>
            Each of the parties will be entitled to enforce its rights in the
            event of a breach of the terms of this privacy policy, to recover
            damages caused by any breach of the provisions herein and to
            exercise all other rights existing under law. Any claim or dispute
            arising in relation to this privacy policy shall be subject to the
            jurisdiction of the courts of the Federal Republic of Nigeria. We
            shall not be liable for any breach where a claim in respect of such
            breach is not brought within one month of the date that such breach
            arose.
          </p>
          <h4>Limitation of liability</h4>
          <p>
            Notwithstanding any other provision in this privacy policy, neither
            Fizido, its affiliates, officers, directors, employees, attorneys or
            agents shall have any liability with respect to, and you hereby
            waive, release and agree not to sue any of them upon, any claim for
            any special, indirect, incidental, consequential damages suffered or
            incurred by you in connection with, arising out of, or in any way
            related to, a breach of this privacy policy.
          </p>
          <h4>Access to information</h4>
          <p>
            The Act gives you the right to access information held about you.
            Your right of access can be exercised in accordance with the Act.
            Any access request may be subject to a fee in providing you with
            details of the information we hold about you.
          </p>
          <p>
            We may update this policy from time to time by publishing a new
            version on our website. You should check this page occasionally to
            ensure you understand any changes to this policy.
          </p>
          <h4>Contact Us</h4>
          <p>
            Questions, comments and requests regarding this privacy policy are
            welcomed and should be addressed to Fizido via the in-app support
            feature on the mobile application or using the support form on our
            website: https://www.fizido.com/contact/
          </p>
        </Container>
      </Container>
    </div>
  );
};

export default Privacy;
