import { Col, Container, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Pro1 from "./product-1.png";
import Pro2 from "./product-2.png";
import "./Layout.css";
import Button from "@restart/ui/esm/Button";

const Layout = () => {
  return (
    <Container fluid="md" className="mt-5 mb-0 layout-con">
      <Row className="my-5">
        <Col lg="5" className="proimg" data-aos="zoom-in">
          <img src={Pro1} alt="" />
        </Col>
        <Col lg="7" className="mt-3" data-aos="fade-up">
          <h1 className="layhd p-0">
            <span className="underline">B</span>ecome a Partner
          </h1>
          <p className="my-4 layp">
            Are you a super agent or an agent with physical presence in Nigeria or other
            countries in Africa? Join the growing list of partners earning revenue and
            other benefits growing together with us in Nigeria and the rest of Africa.
          </p>
          <Nav.Link
            href="https://play.google.com/store/apps/details?id=com.app.fizido"
            target="_blank">
            <Button className="layoutBtn">BECOME A PARTNER</Button>
          </Nav.Link>
        </Col>
        <Col lg="7" className="my-4 text-end" data-aos="fade-up" data-aos-delay="100">
          <h1 className="layhd p-0">
            Join as a Ban
            <span className="underline">k</span>
          </h1>
          <p className="my-4 layp">
            Are you a Bank, Lender or Non-Financial Institution with interest in small
            businesses in Nigeria and the rest of Africa? <br />
            Join the growing list of partner Banks offering financial services to small
            businesses in Nigeria and the rest of Africa on our network.
          </p>
          <Nav.Link
            href="https://play.google.com/store/apps/details?id=com.app.fizido"
            target="_blank">
            <Button className="layoutBtn">JOIN AS A BANK</Button>
          </Nav.Link>
        </Col>
        <Col lg="5" className="proimg" data-aos="zoom-in" data-aos-delay="100">
          <img src={Pro2} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
