import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./NewsLetter.css";
import Swal from "sweetalert2";

const NewsLetter = () => {
  const form = useRef();

  const serviceId = process.env.REACT_APP_SERVICEID;
  const templateId = process.env.REACT_APP_NEWSID;
  const userId = process.env.REACT_APP_USERID;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form.current, userId).then(
      (result) => {
        Swal.fire("Subscribed!", "", "success");
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    form.current.reset();
  };
  return (
    <Container fluid="md" className="my-5 py-2 ">
      <Row className="my-5">
        <Col md="6" className="my-5 my-md-0 ps-3 ps-md-0">
          <h6 className="Newshd">
            Subscribe to our <span className="newbd">Newsletter</span>
          </h6>
          <h6 className="Newsp">Get notified of news and updates first.</h6>
        </Col>
        <Col md="6">
          <form
            ref={form}
            onSubmit={sendEmail}
            method="post"
            className="d-flex ps-3 ps-md-0"
          >
            <input
              type="email"
              name="user_email"
              className="form-control subForm"
              placeholder="Email Address"
            />
            <Button className="subBTN" type="submit">
              Subscribe
            </Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsLetter;
