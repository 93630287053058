import { Col, Container, Row } from "react-bootstrap";
import HTW from "./how-works.png";
import { GiTakeMyMoney, GiCardPlay } from "react-icons/gi";
import { BsPersonBoundingBox, BsCalculatorFill } from "react-icons/bs";

import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <Container fluid="lg" className="mt-5 mb-3">
      <Row>
        <Col
          lg="5"
          className="my-5 proimg"
          data-aos="fade-up-right"
          data-aos-delay="250"
        >
          <img src={HTW} alt=" how it works" className="" />
        </Col>
        <Col lg="7" className="mt-5 text-start">
          <div data-aos="fade-up">
            <h1 className="hIThd">
              <span className="underline">H</span>ow it Works
            </h1>
            <p className="text-muted mt-3 mb-5">
              Follow these 3 simple steps to take payments faster and
              conveniently.
            </p>
          </div>
          <div className="d-flex flex-md-row flex-column ">
            <span className="grp m-md-0 m-auto" data-aos="fade-down">
              <BsPersonBoundingBox className="HITICONS" />
              <p>Register online Or visit an agent</p>
            </span>
            <svg
              version="1.1"
              id="dashed-line-2"
              className="dashed-line _right appear-animation-visible mt-4 d-md-inline d-none"
              x="0px"
              y="0px"
              width="98.5px"
              height="26.5px"
              viewBox="0 0 98.5 26.5"
              enableBackground="new 0 0 98.5 26.5"
              data-appear-animation-svg="1"
            >
              <path
                className="line appear-animation line-anim appear-animation-visible"
                data-appear-animation="line-anim"
                data-appear-animation-duration="1s"
                data-appear-animation-delay="4000"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,9.57c0,0,40.589,36.871,83.192-1.084"
              ></path>
              <path
                className="line-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,9.57c0,0,40.589,36.871,83.192-1.084"
              ></path>
              <circle
                className="circle appear-animation line-circle-anim appear-animation-visible"
                data-appear-animation="line-circle-anim"
                data-appear-animation-duration="1000ms"
                data-appear-animation-delay="4700"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="5.486"
                r="4.627"
              ></circle>
              <circle
                className="circle-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="5.486"
                r="4.627"
              ></circle>
            </svg>
            <span className="grp m-md-0 m-auto" data-aos="fade-right">
              <BsCalculatorFill className="HITICONS" />
              <p>Request for POS online or visit an agent</p>
            </span>
            <svg
              version="1.1"
              id="dashed-line-1"
              className="dashed-line _left appear-animation-visible d-md-inline d-none"
              x="0px"
              y="0px"
              width="98.5px"
              height="26.5px"
              viewBox="0 0 98.5 26.5"
              enableBackground="new 0 0 98.5 26.5"
              data-appear-animation-svg="1"
            >
              <path
                className="line appear-animation line-anim appear-animation-visible"
                data-appear-animation="line-anim"
                data-appear-animation-duration="1000ms"
                data-appear-animation-delay="2000"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,16.93c0,0,40.589-36.872,83.192,1.084"
              ></path>
              <path
                className="line-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,16.93c0,0,40.589-36.872,83.192,1.084"
              ></path>
              <circle
                className="circle appear-animation line-circle-anim appear-animation-visible"
                data-appear-animation="line-circle-anim"
                data-appear-animation-duration="700ms"
                data-appear-animation-delay="2700"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="21.014"
                r="4.627"
              ></circle>
              <circle
                className="circle-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="21.014"
                r="4.627"
              ></circle>
            </svg>
            <span className="grp m-md-0 m-auto" data-aos="fade-left">
              <GiCardPlay className="HITICONS" />
              <p>Activate POS to start taking payments</p>
            </span>
            <svg
              version="1.1"
              id="dashed-line-2"
              className="dashed-line _right appear-animation-visible mt-4 d-md-inline d-none"
              x="0px"
              y="0px"
              width="98.5px"
              height="26.5px"
              viewBox="0 0 98.5 26.5"
              enableBackground="new 0 0 98.5 26.5"
              data-appear-animation-svg="1"
            >
              <path
                className="line appear-animation line-anim appear-animation-visible"
                data-appear-animation="line-anim"
                data-appear-animation-duration="1s"
                data-appear-animation-delay="4000"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,9.57c0,0,40.589,36.871,83.192-1.084"
              ></path>
              <path
                className="line-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                d="M4,9.57c0,0,40.589,36.871,83.192-1.084"
              ></path>
              <circle
                className="circle appear-animation line-circle-anim appear-animation-visible"
                data-appear-animation="line-circle-anim"
                data-appear-animation-duration="1000ms"
                data-appear-animation-delay="4700"
                fill="none"
                stroke="#010101"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="5.486"
                r="4.627"
              ></circle>
              <circle
                className="circle-mask"
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeDasharray="3,3"
                cx="91.01"
                cy="5.486"
                r="4.627"
              ></circle>
            </svg>
            <span className="grp m-md-0 m-auto" data-aos="fade-up">
              <GiTakeMyMoney className="HITICONS" />
              <p>Access loan for your business</p>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HowItWorks;
