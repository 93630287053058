import { Col, Container, Nav, Row } from "react-bootstrap";
import {
  AiOutlineCopyright,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FaGreaterThan } from "react-icons/fa";

import "./Footer.css";

const Footer = () => {
  return (
    <Container fluid="md" className="mt-5 mb-2">
      <Row>
        <Col md="6" className="d-flex footerp">
          <p className="pt-1">
            <AiOutlineCopyright /> copyright 2022. All rights reserved
          </p>
          <span className="socials ms-4 d-flex">
            <Nav.Link href="https://facebook.com/getfizido" className="p-0">
              <AiFillFacebook className="footIcon" />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/getfizido" className="p-0">
              <AiFillTwitterSquare className="footIcon" />
            </Nav.Link>
            <Nav.Link href="https://instagram.com/getfizido" className="p-0">
              <AiOutlineInstagram className="footIcon" />
            </Nav.Link>
          </span>
        </Col>
        <Col lg="6" className="footerp">
          <div className="w-md-50 d-flex float-md-end">
            <Nav.Link href="#Home" className=" pt-0 d-flex ftext">
              <FaGreaterThan className="footericn me-2" />
              <p>HOME</p>
            </Nav.Link>
            <Nav.Link href="#partner" className="pt-0 d-flex ftext">
              <FaGreaterThan className="footericn me-2" />
              <p>PARTNERS</p>
            </Nav.Link>
            <Nav.Link href="#KeyFeatures" className="pt-0 d-flex ftext">
              <FaGreaterThan className="footericn me-2" />
              <p>BENEFITS</p>
            </Nav.Link>
            <Nav.Link href="/privacy-policy" className="pt-0 d-flex ftext">
              <FaGreaterThan className="footericn me-2" />
              <p>PRIVACY POLICY</p>
            </Nav.Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
