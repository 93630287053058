import "./counter.css";
import CountUp from "react-countup";
import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { GiPlanetConquest } from "react-icons/gi";
import { GrConnect } from "react-icons/gr";
import { MdGroups } from "react-icons/md";
import { Col, Container, Row } from "react-bootstrap";

class Counter extends Component {
  state = {
    didViewCountUp: false,
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    return (
      <Container fluid className="Counter-bg py-1">
        <Container fluid="md" className="pt-5 text-center">
          <Row>
            <Col md="4" className="pb-5">
              <p className="countTXT">
                <GiPlanetConquest className="counterIcon mb-2" />
                <span className="num">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{
                      top: 10,
                    }}
                    delayedCall>
                    <CountUp
                      className="CountUp"
                      start={0}
                      end={this.state.didViewCountUp ? 150 : 0}
                      separator=","
                      suffix="+"
                      duration={2}
                    />
                  </VisibilitySensor>
                </span>
                Agents Nationwide
              </p>
            </Col>
            <Col md="4">
              <p className="countTXT pb-5">
                <GrConnect className="counterIcon mb-2" />
                <span className="num">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{
                      top: 10,
                    }}
                    delayedCall>
                    <CountUp
                      className="CountUp"
                      start={0}
                      end={this.state.didViewCountUp ? 200 : 0}
                      suffix="+"
                      separator=","
                      duration={2}
                    />
                  </VisibilitySensor>
                </span>
                MSMEs Connected
              </p>
            </Col>
            <Col md="4" className="pb-4" id="KeyFeatures">
              <p className="countTXT">
                <MdGroups className="counterIcon mb-2" />
                <span className="num">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{
                      top: 10,
                    }}
                    delayedCall>
                    <CountUp
                      className="CountUp"
                      start={0}
                      end={this.state.didViewCountUp ? 5000 : 0}
                      separator=","
                      suffix="+"
                      duration={2}
                    />
                  </VisibilitySensor>
                </span>
                Users Served
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Counter;
