import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Col, Container, Row } from "react-bootstrap";
import { HiOutlineMail, HiPhone } from "react-icons/hi";
import "./ContactForm.css";
import Swal from "sweetalert2";

const ContactForm = () => {
  const form = useRef();
  const serviceId = process.env.REACT_APP_SERVICEID;
  const templateId = process.env.REACT_APP_CONTACTID;
  const userId = process.env.REACT_APP_USERID;
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form.current, userId).then(
      (result) => {
        Swal.fire("Sent!", "", "success");
        console.log(result.text);
      },
      (error) => {
        Swal.fire("Message not sent!", "", "info");
        console.log(error.text);
      }
    );
    form.current.reset();
  };
  return (
    <Container fluid className="contactfrm">
      <Container className="my-5 text-white">
        <div className="py-5 w-md-100 w-50 m-auto">
          <h1 className="Contacthd p-0 mb-2 text-center">
            <span className="undrlid">C</span>ontact Us
          </h1>
          <p className="Contactp py-3 text-center">
            Feel free to ask for details, don't save any questions!
          </p>
          <div className="d-flex flex-md-row flex-column justify-content-between">
            <p>
              <HiPhone size="1.5em" />
              <span className="fw-bolder mx-1">Phone:</span>(+234) 813 520 9415
            </p>
            <p>
              <HiOutlineMail size="1.5em" />
              <span className="fw-bolder mx-1">Email:</span>
              hello@fizido.com
            </p>
          </div>
        </div>
        <Row className="mb-6 gx-0 gx-md-5">
          <Col lg="7" className="mb-5 m-auto">
            <form
              className="text-center"
              ref={form}
              onSubmit={sendEmail}
              method="post"
            >
              <div className="d-md-flex justify-content-between mb-3">
                <div className="me-lg-1 w-lg-50 w-100 text-start">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-bold"
                  >
                    Your Name *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Full-name"
                    required
                    name="user_name"
                  />
                </div>
                <div className="ms-lg-1 w-lg-50 w-100 text-start">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-bold"
                  >
                    Your Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    aria-describedby="NumberHelp"
                    placeholder="e.g +234 8012345678"
                    required
                    name="user_tel"
                  />
                </div>
              </div>

              <div className="mb-3 text-start">
                <label htmlFor="Email Address" className="form-label fw-bold">
                  Email Address *
                </label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="text"
                  placeholder="Enter your email address"
                  required
                  name="user_email"
                />
              </div>
              <div className="mb-3 text-start">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label fw-bold"
                >
                  Your Message *
                </label>
                <textarea
                  name="Message"
                  cols="30"
                  rows="6"
                  className="form-control"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>
              <button type="submit" className="Contactbtn px-4 text-center">
                SEND MESSAGE
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactForm;
