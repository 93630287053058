import Navbar from "../components/navbar/navBar";
import Home from "../components/Home/Home";
import AppDownload from "../components/AppDownload/AppDownload";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import Counter from "../components/counter/Counter";
import KeyFeatures from "../components/Benefits/Benefits";
import Layout from "../components/Lay out/Layout";
// import Reviews from "./components/Reviews/Reviews";
import FAQs from "../components/FAQs/FAQs";
import ContactForm from "../components/Contact/ContactForm";
import NewsLetter from "../components/NewsLetter/NewsLetter";
import Footer from "../components/Footer/Footer";

const Homepage = () => {
  return (
    <>
      <Navbar />
      <Home />
      <AppDownload />
      <HowItWorks />
      <Counter />
      <KeyFeatures />
      <Layout />
      {/* <Reviews /> */}
      <FAQs />
      <ContactForm />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Homepage;
