import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BackToTop from "./components/Back to Top/BackToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import HomePage from "./page/HomePage";
import Privacy from "./page/Privacy";

const App = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      offset: 0,
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <Router>
      <>
        <Switch>
          <Route path="/" exact="true">
            <HomePage />
          </Route>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
        </Switch>

        <BackToTop />
      </>
    </Router>
  );
};

export default App;
