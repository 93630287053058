import "react-bootstrap";
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logoimg from "../../logo/white_logo_transparent_background.png";
import "./nav.css";

const Navigationbar = () => {
  // scroll effect
  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] =
    useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 1);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={isScrollValueMoreThanHeaderHeight ? "dark" : "Nav-bg"}
      variant="dark"
      fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img src={logoimg} className="logo" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto text-center">
            <Nav.Link href="/" className="navItem my-3 mx-md-2 my-md-0">
              Home
            </Nav.Link>
            <Nav.Link href="#about" className="navItem my-3 mx-md-2 my-md-0">
              How It Works
            </Nav.Link>
            <Nav.Link href="#KeyFeatures" className="navItem my-3 mx-md-2 my-md-0">
              Benefits
            </Nav.Link>
            <Nav.Link href="#partner" className="navItem my-3 mx-md-2 my-md-0">
              Partners
            </Nav.Link>
            <Nav.Link href="#FAQs" className="navItem my-3 mx-md-2 my-md-0">
              FAQ
            </Nav.Link>
            <Nav.Link href="#Contact" className="navItem my-3 mx-md-2 my-md-0">
              Contact
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              href="https://play.google.com/store/apps/details?id=com.app.fizido"
              target="_blank">
              <Button variant="primary" className="m-2 keybtn">
                SIGN UP
              </Button>
            </Nav.Link>
            <Nav.Link href="https://online.fizido.com" target="_blank">
              <Button variant="primary" className="m-2 keybtn">
                LOGIN
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
