import "./FAQs.css";
import { Accordion, Container } from "react-bootstrap";

const FAQs = () => {
  return (
    <Container fluid="md" className="my-5">
      <div className="faq-hd text-center w-75 m-auto">
        <h2 className="faqshd">
          Frequently A<span className="underline">sk</span>ed Questions
        </h2>
        <p className="faqsp my-4">
          Below you will find answers to the questions we get asked the most
          about.
        </p>
      </div>
      <Accordion defaultActiveKey="0" className="my-5 pb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="hd ">What is Fizido?</Accordion.Header>
          <Accordion.Body>
            Fizido is a payment app and POS powered by Support Microfinance Bank
            Limited
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Is Fizido duly registered and recognized in Nigeria?
          </Accordion.Header>
          <Accordion.Body>
            Yes, Fizido Digital Services Limited is a registered Limited
            Liability Company with the Corporate Affairs Commission (CAC) in
            Nigeria.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="faqhd">
            Is Fizido licensed by the Central Bank of Nigeria?
          </Accordion.Header>
          <Accordion.Body>
            Yes, Fizido is a product of CBN-licensed and NDIC-insured Support
            Microfinance Bank Limited
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="fw-bold">
            Are savings and deposits safe and insured?
          </Accordion.Header>
          <Accordion.Body>
            Yes, our deposit and savings scheme is powered by Support
            Microfinance Bank Limited
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className="faqhd">
            How do I receive my Payment POS?
          </Accordion.Header>
          <Accordion.Body>
            Visit the nearest Fizido agent to you and obtain your Fizido POS
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header className="faqhd">
            How do I access my Fizido wallet?
          </Accordion.Header>
          <Accordion.Body>
            Click "Don’t have a Password? Activate" on Fizido mobile app or
            Fizido online to setup a password to access your Fizido wallet
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header className="faqhd">
            How do I activate my Fizido POS?
          </Accordion.Header>
          <Accordion.Body>
            Enter the activation code sent to you via SMS on the POS. Your POS
            will be activated immediately and you can start taking payment.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7" id="Contact">
          <Accordion.Header className="faqhd">
            How do I get support for the POS?
          </Accordion.Header>
          <Accordion.Body>
            Please visit the agent that delivered the POS to you for support.
            You can also chat us or call
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <div className="que text-center">
          <h5>Do you have any <span className="fw-bold">Questions ?</span></h5>
          <p>Check our community form</p>
          <button className="mt-3 layoutBtn">
              GO TO COMMUNITY
          </button>
      </div> */}
    </Container>
  );
};

export default FAQs;
